'use strict';(function (window, doc, $) {
  if ($('form#pcp-details-form').length > 0) {
    $('.datepicker-here').datepicker({
      autoClose: true });

    var frm = document.querySelector('form#pcp-details-form');
    console.log('form ', frm);
    var rpf = new RPForms(frm, {
      fields: [
      '#pcp-eff-date-id',
      '#pcp-change-reason-list'],

      checks: [
      'ExistingPatient'] });

    frm.addEventListener('submit', function (evt) {
      if (document.querySelectorAll('input[name="ExistingPatient"]:checked').length < 1) {
        evt.preventDefault(evt);
      }
    }, false);
  }
})(window, document, jQuery);