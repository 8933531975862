'use strict';var _createClass = function () {function defineProperties(target, props) {for (var i = 0; i < props.length; i++) {var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);}}return function (Constructor, protoProps, staticProps) {if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;};}();function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}var MenuIcon =
function MenuIcon(openIcon, closeIcon, onEvent, offEvent) {_classCallCheck(this, MenuIcon);
	this.openIcon = openIcon;
	this.closeIcon = closeIcon;
	this.onEvent = onEvent;
	this.offEvent = offEvent;
};var

MenuToggle = function () {
	function MenuToggle(isMobile) {_classCallCheck(this, MenuToggle);
		this.mobileEvent = 'click';
		this.desktopEvent = 'mouseenter mouseleave';
		this.svgParentSelector = '.nav-item .nav-item__icon';
		this.anchorSelector = '.nav-item__element';
		this.insideAnchorSelector = '.nav-item__element span, .nav-item__element i';
		this.svgSelector = '.nav-item .nav-item__icon svg';
		this.useSelector = '.nav-item .nav-item__icon svg use';

		this.isMobile = isMobile;
		this.mobileIcons = new MenuIcon('#gmd-ic-add-24px', '#gmd-ic-remove-24px', this.mobileEvent, this.desktopEvent);
		this.desktopIcons = new MenuIcon('#gmd-ic-keyboard-arrow-down-24px', '#gmd-ic-keyboard-arrow-up-24px', this.desktopEvent, this.mobileEvent);
		// this.openDesktopIcon = '#gmd-ic-keyboard-arrow-down-24px';
	}_createClass(MenuToggle, [{ key: 'turnOffAllListeners', value: function turnOffAllListeners()
		{
			$(this.svgParentSelector).off(this.desktopEvent);
			$(this.anchorSelector).off(this.desktopEvent);
			$(this.insideAnchorSelector).off(this.desktopEvent);
			$(this.svgParentSelector).off(this.mobileEvent);
			$(this.anchorSelector).off(this.mobileEvent);
			$(this.insideAnchorSelector).off(this.mobileEvent);
		} }, { key: 'platformOpenIcon', value: function platformOpenIcon()
		{
			var result = this.desktopIcons.openIcon;
			if (this.isMobile) {
				result = this.mobileIcons.openIcon;
			}
			return result;
		} }, { key: 'platformCloseIcon', value: function platformCloseIcon()
		{
			var result = this.desktopIcons.closeIcon;
			if (this.isMobile) {
				result = this.mobileIcons.closeIcon;
			}
			return result;
		} }, { key: 'platformOnEvent', value: function platformOnEvent()
		{
			var result = this.desktopIcons.onEvent;
			if (this.isMobile) {
				result = this.mobileIcons.onEvent;
			}
			return result;
		} }]);return MenuToggle;}();