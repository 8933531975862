'use strict';(function (w, d, $) {
  function messageMobileNav() {
    var messageRow = null;
    if ($('.message-row a.readMessage').length > 0 && RPUtil.viewportWidth() < 865) {
      $('.message-row').each(function (indx, el) {
        var aLink = $(el).find('a.readMessage');
        messageRow = aLink.closest('.message-row');
        if (!messageRowHasClick(messageRow)) {
          messageRow.click(function (e) {
            document.location.href = aLink.get(0).href;
          });
          messageRow.attr('data-evt', 'click');
        }
      });
    } else
    {
      if ($('.message-row a.readMessage').length > 0 && RPUtil.viewportWidth() > 865) {
        $('.message-row').each(function (indx, el) {
          var aLink = $(el).find('a.readMessage');
          messageRow = aLink.closest('.message-row');
          if (messageRowHasClick(messageRow)) {
            messageRow.removeAttr('data-evt');
            messageRow.unbind('click');
          }
        });
      }
    }
  }

  function messageRowHasClick(msgRow) {
    if (typeof msgRow.attr('data-evt') !== 'undefined' && msgRow.attr('data-evt').length > 0) {
      return true;
    } else
    {
      return false;
    }
  }

  function searchValidate() {
    if ($('input[name="messages-search--input"]').length > 0) {
      $('.search-icon input[type="submit"]').click(function (e) {
        if ($('input[name="messages-search--input"]').get(0).checkValidity() === false) {
          $('.messages-search--input-container').css('border', '1px solid #d50000');
        }
      });
    }
  }

  function composeValidate() {
    if ($('form.messages-compose--pane').length > 0) {
      var frm = document.querySelector('form.messages-compose--pane');
      var rpf = new RPForms(frm, {
        fields: [
        '#send-to',
        '#subject',
        '#message'] });

    }
  }

  function replyValidate() {
    if ($('textarea.reply-text').length > 0) {
      var tex = $('textarea.reply-text');
      var ta = void 0,frm = void 0,btn = null;
      tex.each(function (indx, el) {
        frm = $(el).parent('form');
        btn = frm.find('input[type="submit"]');
        if (!RPUtil.isUndefined(frm) && !RPUtil.isNullOrEmpty(frm) && RPUtil.isUndefined(frm.attr('data-evt'))) {
          btn.click(function (evt) {
            textValid($(el));
          });
          frm.submit(function (e) {
            e.preventDefault();
            if (el.checkValidity()) {
              console.log('submit');
              return true;
            } else
            {
              return false;
            }
          });
          frm.attr('data-evt', 'sub');
        }
      });
    }
  }

  function textValid(tex) {
    if (tex.get(0).validity.valueMissing || !tex.get(0).validity.valid || !tex.get(0).checkValidity()) {
      tex.addClass('form-control--invalid');
      return false;
    } else
    {
      tex.removeClass('form-control--invalid');
      return true;
    }
  }

  RPUtil.resize(w, function () {
    messageMobileNav();
  });

  RPUtil.loaded(d, function () {
    messageMobileNav();
    searchValidate();
    replyValidate();
    composeValidate();
  });
})(window, document, jQuery);