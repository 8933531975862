"use strict";function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}var FormValidateErrorDivHandler =
function FormValidateErrorDivHandler(submitButtonSelector, formValidatedFn, errorMsgDivSelector, onclickRemoveErrMsgSelector) {_classCallCheck(this, FormValidateErrorDivHandler);
  var fadeDuration = 200;
  $(submitButtonSelector).click(function (event) {
    var formValidated = formValidatedFn();
    if (!formValidated) {
      $(errorMsgDivSelector).show(fadeDuration);
    } else {
      $(errorMsgDivSelector).hide(fadeDuration);
    }
    return formValidated;
  });
  $(onclickRemoveErrMsgSelector).click(function (event) {
    $(errorMsgDivSelector).hide(fadeDuration);
  });
  $(errorMsgDivSelector).hide(fadeDuration);
};