'use strict';var _createClass = function () {function defineProperties(target, props) {for (var i = 0; i < props.length; i++) {var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);}}return function (Constructor, protoProps, staticProps) {if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;};}();function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}var RPForms = function () {
  function RPForms(formElement, config) {_classCallCheck(this, RPForms);
    this._form = formElement;
    this._fields = config.fields || {};
    this._checks = config.checks || {};
    this._applyHiddenCheckboxHandling = config.applyHiddenCheckboxHandling || false;
    this._submitbtn = this.getSubmitBtn();
    this.init();
  }_createClass(RPForms, [{ key: 'getForm', value: function getForm()

    {
      return this._form;
    } }, { key: 'getSubmitBtn', value: function getSubmitBtn()

    {
      return this._form.querySelector('input[type="submit"]') || null;
    } }, { key: 'setClick', value: function setClick(

    fn) {
      if (typeof fn === 'function') {
        this.destroy();
        this._click = fn;
        this.init();
      }
    } }, { key: 'setSubmit', value: function setSubmit(

    fn) {
      if (typeof fn === 'function') {
        this.destroy();
        this._submit = fn;
        this.init();
      }
    } }, { key: 'getSubmit', value: function getSubmit()

    {
      if (!RPUtil.isUndefined(this._submit)) {
        return this._submit;
      }
    } }, { key: 'getClick', value: function getClick()

    {
      if (!RPUtil.isUndefined(this._click)) {
        return this._click;
      }
    } }, { key: 'init', value: function init()

    {var _this = this;
      // Provide the inline handlers
      this.inlineFieldHandlers();
      // check for the _submit variable
      if (!RPUtil.isUndefined(this._submit)) {
        this._form.addEventListener('submit', function () {
          _this.validate.bind(_this);
          _this._submit.bind(_this);
        },
        false);
      } else
      {
        // Default form submit handler
        this._form.addEventListener('submit', this.validate.bind(this), false);
      }
      // Check for submit button
      if (this._submitbtn !== null) {
        // Check for the _click variable
        if (!RPUtil.isUndefined(this._click)) {
          this._submitbtn.addEventListener('click', function (evt) {
            _this.validate.bind(_this);
            _this._click();
          },
          false);
        } else
        {
          // Default submit button click handler
          this._submitbtn.addEventListener('click', this.validate.bind(this), false);
        }
      }
      if (this._applyHiddenCheckboxHandling) {
        this.removeRequiredFromChecks();
      }
    } }, { key: 'removeRequiredFromChecks', value: function removeRequiredFromChecks()

    {
      if (this._checks.length > 0) {
        var tmp = null;
        [].forEach.call(this._checks, function (fld, indx) {
          tmp = document.querySelectorAll('input[name="' + fld + '"]');
          if (tmp.length > 0) {
            [].forEach.call(tmp, function (ffld, indx) {
              ffld.removeAttribute('required');
            });
          }
        });
      }
    } }, { key: 'inlineFieldHandlers', value: function inlineFieldHandlers()

    {var _this2 = this;
      if (this._fields.length > 0) {
        var tmp = null;
        [].forEach.call(this._fields, function (fld, indx) {
          tmp = document.querySelector(fld);
          if (tmp) {
            tmp.removeEventListener('change', _this2.validateField);
            tmp.removeEventListener('blur', _this2.validateField);
            tmp.removeEventListener('keyup', _this2.validateField);
            tmp.addEventListener('change', _this2.validateField.bind(_this2), false);
            tmp.addEventListener('blur', _this2.validateField.bind(_this2), false);
            tmp.addEventListener('keyup', _this2.validateField.bind(_this2), false);
          }
        });
      }
      if (this._checks.length > 0) {
        var _tmp = null;
        [].forEach.call(this._checks, function (fld, indx) {
          _tmp = document.querySelectorAll('input[name="' + fld + '"]');
          if (_tmp.length > 0) {
            [].forEach.call(_tmp, function (ffld, indx) {
              ffld.addEventListener('change', _this2.validateCheckBox.bind(_this2), false);
              ffld.addEventListener('blur', _this2.validateCheckBox.bind(_this2), false);
            });
          }
        });
      }
    } }, { key: 'validateField', value: function validateField(

    evt) {
      if (evt.type === 'keyup' && evt.keyCode === 9) {
        return;
      } else
      {
        if (!evt.target.validity.valid) {
          if (!RPUtil.hasClass(evt.target, 'form-control--invalid')) {
            evt.target.classList.add('form-control--invalid');
          }
        } else
        {
          evt.target.classList.remove('form-control--invalid');
        }
      }
    } }, { key: 'validateCheckBox', value: function validateCheckBox()

    {
      var isValid = true;
      if (this._checks.length > 0) {
        var tmp = [];
        for (var i = 0; i < this._checks.length; i++) {
          if (document.querySelectorAll('input[name="' + this._checks[i] + '"]:checked').length > 0) {
            tmp[i] = true;
          } else
          {
            tmp[i] = false;
          }
        }
        if (tmp.indexOf(false) >= 0) {
          isValid = false;
          this.writeError();
        } else
        {
          RPForms.deleteError();
        }
      }
      return isValid;
    } }, { key: 'validate', value: function validate(

    evt) {
      if (this._fields.length > 0) {
        for (var i = 0; i < this._fields.length; i++) {
          var el = document.querySelector(this._fields[i]);
          if (el.hasAttribute('required') && !el.validity.valid) {
            if (!RPUtil.hasClass(el, 'form-control--invalid')) {
              el.classList.add('form-control--invalid');
            }
          } else
          {
            el.classList.remove('form-control--invalid');
          }
        }
        if (evt.type === 'submit') {
          // Handle submit or evt.preventDefault()
        }
      }
      if (this._checks.length > 0) {
        var isValid = this.validateCheckBox();
        if (!isValid && this._applyHiddenCheckboxHandling) {
          evt.preventDefault();
        }
      }
    } }, { key: 'writeError', value: function writeError()

    {
      if (document.querySelectorAll('#write-error').length > 0) {
        var tmp = document.getElementById('write-error');
        tmp.style.color = 'red';
        tmp.innerHTML = 'Please make a selection before proceeding.';
      }
    } }, { key: 'destroy', value: function destroy()








    {
      this._form.removeEventListener('submit', this.validate.bind(this), false);
      if (typeof this._submit !== 'undefined' && this._submit !== null) {
        this._form.removeEventListener('click', this.validate.bind(this), false);
      }
    } }], [{ key: 'deleteError', value: function deleteError() {var eel = document.querySelectorAll('#write-error');if (eel.length > 0) {eel[0].innerHTML = '';}} }]);return RPForms;}();