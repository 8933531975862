'use strict';if ($('form#login-form').length > 0) {
  var frm = document.querySelector('form#login-form');
  if ($('form#login-form input').length === 3) {
    var rpf = new RPForms(frm, {
      fields: [
      '#username',
      '#password'] });

  } else if ($('form#login-form input').length === 2) {
    var _rpf = new RPForms(frm, {
      fields: [
      '#member-id'] });

  }
}

if ($('form#reset-password-form').length > 0) {
  var _frm = document.querySelector('form#reset-password-form');
  if ($('form#reset-password-form input[type=text]').length === 4) {
    var _rpf2 = new RPForms(_frm, {
      fields: [
      '#ghpid',
      '#dob',
      '#zip',
      '#ss',
      '#email'] });

  } else if ($('form#reset-password-form input[type=password]').length === 2) {
    var _rpf3 = new RPForms(_frm, {
      fields: [
      '#password1',
      '#password2'] });

    _rpf3.setClick(function (evt) {
      password1val = $('form#reset-password-form input#password1').val();
      password2val = $('form#reset-password-form input#password2').val();
      if ($('form#reset-password-form #written-error').length > 0) {
        $('form#reset-password-form #written-error').text('');
      }
      if (password1val != password2val) {
        if ($('form#reset-password-form #written-error').length > 0) {
          $('form#reset-password-form #written-error').text('Your passwords do not match.');
        } else {
          $('form#reset-password-form .field-container').before('<p id="written-error" style="color:red;">Your passwords do not match.</p>');
        }
        $('form#reset-password-form').submit(passwordMismatch);
      } else {
        $('form#reset-password-form').unbind('submit', passwordMismatch);
      }
    });
  }
}

if ($('form#reset-primary-email-form').length > 0) {
  var _frm2 = document.querySelector('form#reset-primary-email-form');
  var _rpf4 = new RPForms(_frm2, {
    fields: [
    '#primary-email1'] });

}

if ($('form#reset-secondary-email-form').length > 0) {
  var _frm3 = document.querySelector('form#reset-secondary-email-form');
  var _rpf5 = new RPForms(_frm3, {
    fields: [
    '#secondary-email1'] });

}

if ($('form#reset-challenge-q-form').length > 0) {
  var _frm4 = document.querySelector('form#reset-challenge-q-form');
  var _rpf6 = new RPForms(_frm4, {
    fields: [
    '#challenge-q-answer'] });

}

var registerPassword1 = '#Credentials_Password';
var registerPassword2 = '#Credentials_ConfirmPassword';
if ($('form#user-id-page-form').length > 0) {
  var _frm5 = document.querySelector('form#user-id-page-form');
  var _rpf7 = new RPForms(_frm5, {
    // fields: [
    //   '#password1',
    //   '#password2',
    //   '#username'
    // ]});
    fields: [
    registerPassword1,
    registerPassword2,
    '#Credentials_Username'] });

  _rpf7.setClick(function (evt) {
    if ($('form#user-id-page-form #written-error').length > 0) {
      $('form#user-id-page-form #written-error').text('');
    }
    password1val = $('form#user-id-page-form input' + registerPassword1).val();
    password2val = $('form#user-id-page-form input' + registerPassword2).val();
    if (password1val != '' && password2val != '' && password1val !== password2val) {
      if ($('form#user-id-page-form #written-error').length > 0) {
        $('form#user-id-page-form #written-error').text('Your passwords do not match.');
      } else {
        $('form#user-id-page-form .field-container').before('<p id="written-error" style="color:red;">Your passwords do not match.</p>');
      }
      $('form#user-id-page-form').submit(registerPasswordMismatch);
    } else {
      $('form#user-id-page-form').unbind('submit', registerPasswordMismatch);
    }
  });
}

if ($('form.accept-form').length > 0) {
  var _frm6 = document.querySelector('form.accept-form');
  var _rpf8 = new RPForms(_frm6, {
    fields: [
    '#cpt-code-id'] });

}
if ($('form#auth-search').length > 0) {
  var _frm7 = document.querySelector('form#auth-search');
  var _rpf9 = new RPForms(_frm7, {
    fields: [
    '#auth-date'] });

}
if ($('form#zipcode-search').length > 0) {
  var _frm8 = document.querySelector('form#zipcode-search');
  var _rpf10 = new RPForms(_frm8, {
    fields: [
    '#zip-id'] });

}
if ($('form#request-new-card2').length > 0) {
  var _frm9 = document.querySelector('form#request-new-card2');
  var _rpf11 = new RPForms(_frm9, {
    checks: [
    'member-id-card-member'] });

}
if ($('form#request-new-card7').length > 0) {
  var _frm10 = document.querySelector('form#request-new-card7');
  var _rpf12 = new RPForms(_frm10, {
    checks: [
    'member-id-card-member'],

    applyHiddenCheckboxHandling: true });

}

function passwordMismatch(evt) {
  $('input#password1, input#password2').addClass('form-control--invalid');
  console.log('passwordMismatch() called');
  evt.preventDefault();
}
function registerPasswordMismatch(evt) {
  $('input' + registerPassword1 + ', input' + registerPassword2).addClass('form-control--invalid');
  evt.preventDefault();
}