'use strict';
var inputMaskOptions = {
  inputOptions: {
    date: [{
      attr: 'pattern',
      val: '(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\\d\\d' },
    {
      attr: 'maxlength',
      val: '10' }],

    phone: [{
      attr: 'pattern',
      val: '[\\(]\\d{3}[\\)]\\s?\\d{3}[\\-]\\d{4}' }],

    zip: [{
      attr: 'pattern',
      val: '(\\d{5}([\\-]\\d{4})?)' }] },


  datepickerOptions: {
    autoClose: true,
    keyboardNav: false,
    dateFormat: 'mm/dd/yyyy',
    view: 'years',
    minView: 'days',
    maxDate: '',
    language: 'en' },

  inputMaskDateOptions: {
    alias: 'datetime',
    inputFormat: 'mm/dd/yyyy',
    placeholder: ' ',
    showMaskOnHover: false,
    showMaskOnFocus: false,
    greedy: false },

  inputTelMaskOptions: {
    placeholder: ' ' },

  inputMaskZipOptions: {
    placeholder: '' } };




function initJqueryItems() {
  function setAttributes($element, identifier) {

    var arrOptions = inputMaskOptions.inputOptions[identifier];

    if (!arrOptions) return; //exit;

    for (var i = 0; i < arrOptions.length; i++) {
      var opt = arrOptions[i];
      if ($element[0] && !$element.attr(opt.attr)) {
        $element.attr(opt.attr, opt.val);
      }
    }
  }

  function initDateInput() {
    var $this = $(this);
    var hasFutureDate = $this.attr('data-future-date') && $this.attr('data-future-date').toLowerCase() === 'true';
    var _datepickerOptions = inputMaskOptions.datepickerOptions;
    _datepickerOptions.maxDate = hasFutureDate ? '' : new Date();

    setAttributes($this, 'date');
    $this.datepicker(_datepickerOptions);
    $this.inputmask(inputMaskOptions.inputMaskDateOptions);
  }

  function initPhoneInput() {
    var $this = $(this);
    setAttributes($this, 'phone');
    $this.inputmask('(999) 999-9999', inputMaskOptions.inputMaskTelOptions);
  }

  function initZipInput() {
    var $this = $(this);
    setAttributes($this, 'zip');
    $this.inputmask('99999[-9999]', inputMaskOptions.inputMaskZipOptions);
  }

  $('[rel="js-datepicker"]').each(initDateInput);
  $('[rel="js-tel-mask"]').each(initPhoneInput);
  $('[rel="js-zip-mask"]').each(initZipInput);
}

window.addEventListener('DOMContentLoaded', initJqueryItems);