'use strict';var _createClass = function () {function defineProperties(target, props) {for (var i = 0; i < props.length; i++) {var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);}}return function (Constructor, protoProps, staticProps) {if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;};}();function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}var BenefitChart = function () {
  function BenefitChart(graphId, graphBoxContainerId, noAccessId, bpMobileValue) {_classCallCheck(this, BenefitChart);
    this.graphId = graphId;
    this.graphBoxContainerId = graphBoxContainerId;
    this.noAccessId = noAccessId;
    this.bpMobileValue = bpMobileValue;
    this.twoThirdsChartWidth = '64%';
    this.oneThirdChartWidth = '30%';

    // Scenario where user does not have access (locked)
    this.showGraph();
    //this.showNoAccessDiv();

    // Scenario where deductible or coinsurance may not exist for graph
    // this.showTwoThirdsOneThirdGraph();
    // this.showAllThirdsGraph();

    // Scenario where deductible or coinsurance may not exist for chart
    // this.showChartState('dedCoinsOop');
    // this.showChartState('dedOop');
    // this.showChartState('coinsOop');
    // this.showChartState('oop');
    // this.showChartState('none');
  }

  // let chartInput = {
  // 	'amountPaidPct': '75%',
  // 	'amountPaidType': 'deductible',	// could be deductible|oopMax|entireWidth
  // 	'amountPaid': 'You are here: $355.00',
  // 	'bulletItem1': '$155 from meeting your deductible',
  // 	'bulletItem2': '$6005 from meeting your max out-of-pocket',
  // 	'deductibleThirdHighlight': true,
  // 	'coinsThirdHighlight': false,
  // 	'oopThirdHighlight': false,
  // 	'deductibleText': 'You pay 100% of costs',
  // 	'coinsuranceText': 'You pay 15% in-network and any copays',
  // 	'oopText': 'You pay 0% of costs',
  // 	'deductibleDollarValueText': 'Deductible: $500.00',
  // 	'maxOopDollarValueText': 'Max Out-of-pocket: $6350.00',
  // 	'deductibleMet': true,
  // 	'oopMet': false
  // };
  // individual in-system
  // let chartInput = {
  //   'amountPaidPct': '300',
  //   'amountPaidType': 'oopMax',
  //   'amountPaid': 'You are here: $5,500.00',
  //   'bulletItem1': '$4,500.00 away from meeting your max out-of-pocket',
  //   'bulletItem2': '',
  //   'deductibleThirdHighlight': false,
  //   'coinsThirdHighlight': true,
  //   'oopThirdHighlight': false,
  //   'deductibleText': 'You pay 100% of costs',
  //   'coinsuranceText': 'You pay 40.00 % in-system and any copays',
  //   'oopText': 'You pay 0% of costs',
  //   'deductibleDollarValueText': 'Deductible: $1,000.00',
  //   'maxOopDollarValueText': 'Max Out-of-pocket: $2,500.00',
  //   'deductibleMet': true,
  //   'oopMet': false,
  //   'hasDeductible': true,
  //   'hasCoinsurance': true
  // }
  // individual in-network
  // let chartInput = {
  //   'amountPaidPct': '90%',
  //   'amountPaidType': 'entireWidth',
  //   'amountPaid': 'You are here: $5,500.00',
  //   'bulletItem1': '',
  //   'bulletItem2': '',
  //   'deductibleThirdHighlight': false,
  //   'coinsThirdHighlight': false,
  //   'oopThirdHighlight': true,
  //   'deductibleText': 'You pay 100% of costs',
  //   'coinsuranceText': '',
  //   'oopText': 'You pay 0% of costs',
  //   'deductibleDollarValueText': 'Deductible: $1,500.00',
  //   'maxOopDollarValueText': 'Max Out-of-pocket: $5,000.00',
  //   'deductibleMet': true,
  //   'oopMet': true,
  //   'hasDeductible': true,
  //   'hasCoinsurance': false
  // }
  // individual out-of-network
  // let chartInput = {
  //   'amountPaidPct': '92',
  //   'amountPaidType': 'oopMax',
  //   'amountPaid': 'You are here: $5,500.00',
  //   'bulletItem1': '$4,500.00 away from meeting your max out-of-pocket',
  //   'bulletItem2': '',
  //   'deductibleThirdHighlight': true,
  //   'coinsThirdHighlight': false,
  //   'oopThirdHighlight': false,
  //   'deductibleText': 'You pay 40.00 % out-of-network and any copays',
  //   'coinsuranceText': '',
  //   'oopText': 'You pay 0% of costs',
  //   'deductibleDollarValueText': '',
  //   'maxOopDollarValueText': 'Max Out-of-pocket: $6,000.00',
  //   'deductibleMet': false,
  //   'oopMet': false,
  //   'hasDeductible': false,
  //   'hasCoinsurance': true
  // }
  // individual out-of-universe
  // let chartInput = {
  //   'amountPaidPct': '55',
  //   'amountPaidType': 'oopMax',
  //   'amountPaid': 'You are here: $5,500.00',
  //   'bulletItem1': '$4,500.00 away from meeting your max out-of-pocket',
  //   'bulletItem2': '',
  //   'deductibleThirdHighlight': true,
  //   'coinsThirdHighlight': false,
  //   'oopThirdHighlight': false,
  //   'deductibleText': '',
  //   'coinsuranceText': '',
  //   'oopText': 'You pay 0% of costs',
  //   'deductibleDollarValueText': '',
  //   'maxOopDollarValueText': 'Max Out-of-pocket: $10,000.00',
  //   'deductibleMet': false,
  //   'oopMet': false,
  //   'hasDeductible': false,
  //   'hasCoinsurance': false
  // }
  // family in-system
  // let chartInput = {
  //   'amountPaidPct': '18',
  //   'amountPaidType': 'deductible',
  //   'amountPaid': 'You are here: $350.00',
  //   'bulletItem1': '$2,650.00 away from meeting your deductible',
  //   'bulletItem2': '$4,250.00 away from meeting your max-out-of-pocket',
  //   'deductibleThirdHighlight': true,
  //   'coinsThirdHighlight': false,
  //   'oopThirdHighlight': false,
  //   'deductibleText': 'You pay 100% of costs',
  //   'coinsuranceText': 'You pay 40.00 % in-system and any copays',
  //   'oopText': 'You pay 0% of costs',
  //   'deductibleDollarValueText': 'Deductible: $2,000.00',
  //   'maxOopDollarValueText': 'Max Out-of-pocket: $5,000.00',
  //   'deductibleMet': false,
  //   'oopMet': false,
  //   'hasDeductible': true,
  //   'hasCoinsurance': true
  // }
  // family in-network
  // let chartInput = {
  //   'amountPaidPct': '12',
  //   'amountPaidType': 'deductible',
  //   'amountPaid': 'You are here: $350.00',
  //   'bulletItem1': '$2,650.00 away from meeting your deductible',
  //   'bulletItem2': '$4,250.00 away from meeting your max out-of-pocket',
  //   'deductibleThirdHighlight': true,
  //   'coinsThirdHighlight': false,
  //   'oopThirdHighlight': false,
  //   'deductibleText': 'You pay 100% of costs',
  //   'coinsuranceText': '',
  //   'oopText': 'You pay 0% of costs',
  //   'deductibleDollarValueText': 'Deductible: $3,000.00',
  //   'maxOopDollarValueText': 'Max Out-of-pocket: $7,000.00',
  //   'deductibleMet': false,
  //   'oopMet': false,
  //   'hasDeductible': true,
  //   'hasCoinsurance': false
  // }
  // family out-of-network
  // let chartInput = {
  //   'amountPaidPct': '08%',
  //   'amountPaidType': 'oopMax',
  //   'amountPaid': 'You are here: $750.00',
  //   'bulletItem1': '$4,250.00 away from meeting your max out-of-pocket',
  //   'bulletItem2': '',
  //   'deductibleThirdHighlight': true,
  //   'coinsThirdHighlight': false,
  //   'oopThirdHighlight': false,
  //   'deductibleText': 'You pay 40.00 % out-of-network and any copays',
  //   'coinsuranceText': '',
  //   'oopText': 'You pay 0% of costs',
  //   'deductibleDollarValueText': '',
  //   'maxOopDollarValueText': 'Max Out-of-pocket: $10,000.00',
  //   'deductibleMet': false,
  //   'oopMet': false,
  //   'hasDeductible': false,
  //   'hasCoinsurance': true
  // }

  // chartState == (dedCoinsOop|ded|coins|dedOop|coinsOop|oop|none)
  _createClass(BenefitChart, [{ key: 'showChartState', value: function showChartState(chartState) {
      var dedChart = $('#ded-chart-id');
      var coinsChart = $('#coins-chart-id');
      var oopChart = $('#oop-chart-id');

      this.resetChartValues(dedChart);
      this.resetChartValues(coinsChart);
      this.resetChartValues(oopChart);
      if (chartState === 'dedCoinsOop') {
      } else if (chartState == 'dedOop') {
        dedChart.css('width', this.twoThirdsChartWidth);
        coinsChart.css('display', 'none');
      } else if (chartState == 'ded') {
        dedChart.css('width', this.twoThirdsChartWidth);
        coinsChart.css('display', 'none');
        oopChart.css('display', 'none');
      } else if (chartState == 'coins') {
        dedChart.css('display', 'none');
        coinsChart.css('width', this.twoThirdsChartWidth);
        oopChart.css('display', 'none');
      } else if (chartState == 'coinsOop') {
        dedChart.css('display', 'none');
        coinsChart.css('width', this.twoThirdsChartWidth);
      } else if (chartState == 'oop') {
        dedChart.css('display', 'none');
        coinsChart.css('display', 'none');
      } else if (chartState == 'none') {
        dedChart.css('display', 'none');
        coinsChart.css('display', 'none');
        oopChart.css('display', 'none');
      }
    } }, { key: 'resetChartValues', value: function resetChartValues(
    elem) {
      elem.css('width', 'this.oneThirdChartWidth');
      elem.css('display', 'inline-block');
    }
    //
    // Scenario where user does not have access
    //
  }, { key: 'showTwoThirdsOneThirdGraph', value: function showTwoThirdsOneThirdGraph() {
      $('#deductible-id').css('width', this.twoThirdsChartWidth);
      $('#deductible-dollar-value-text').css('padding-right', '2rem');
      $('#deductible-checkmark-id').hide();
      $('#coins-id').hide();
      this.applyTextWidths();

    } }, { key: 'showAllThirdsGraph', value: function showAllThirdsGraph()
    {
      $('#deductible-id').css('width', this.oneThirdChartWidth);
      $('#deductible-dollar-value-text').css('padding-right', '0rem');
      $('#deductible-checkmark-id').show();
      $('#coins-id').show();
    } }, { key: 'applyTextWidths', value: function applyTextWidths()
    {
      if (this.isMobile()) {
        $('.meter--deductible-bgnd .meter-third-text').css('right', '7rem');
        $('.meter--deductible-bgnd .meter-third-text').css('width', '50%');
      } else {
        $('.meter--deductible-bgnd .meter-third-text').css('right', '2rem');
        $('.meter--deductible-bgnd .meter-third-text').css('width', '');
      }

      if (this.isMobile()) {
        $('#deductible-label-id').css('width', '50%');
        $('#deductible-label-id').css('left', '11rem');
        $('#deductible-label-id').css('bottom', '3rem');
      } else {
        $('#deductible-label-id').css('width', '100%');
        $('#deductible-label-id').css('left', '1rem');
        $('#deductible-label-id').css('bottom', '');
      }

    } }, { key: 'isMobile', value: function isMobile()
    {
      var result = window.innerWidth <= this.bpMobileValue;
      return result;
    }

    //
    // Scenario where user does not have access
    //
    // presents the benefits graph div and hides
    // the 'You don't have access to this account.' div
  }, { key: 'showGraph', value: function showGraph() {
      $(this.noAccessId).hide();
      $(this.graphBoxContainerId).show();
    }
    // presents the 'You don't have access to this account.' div and
    // hides the benefits graph`
  }, { key: 'showNoAccessDiv', value: function showNoAccessDiv() {
      $(this.noAccessId).show();
      $(this.graphBoxContainerId).hide();
    } }, { key: 'showNone', value: function showNone()
    {
      $(this.noAccessId).hide();
      $(this.graphBoxContainerId).hide();
    }

    //
    // Handle input variables which govern the graph
    //
    // applies chart input values when called
  }, { key: 'applyValues', value: function applyValues(chartInput) {
      var meterHere = $(this.graphId + ' .meter--here');
      var meterProgress = $(this.graphId + ' .meter--progress');
      var amountPaidPct = chartInput.amountPaidPct;
      var amountPaidPctNr = parseFloat(amountPaidPct) / 100.0;

      // console.log('cl 1');
      if (chartInput.amountPaidType === 'deductible') {
        // .30 because: #deductible-id, .meter--deductible-bgnd width value (30% at time of writing)
        // console.log('cl 2');
        amountPaidPctNr *= .30;
      } else if (chartInput.amountPaidType === 'oopMax') {
        // // .64 because: #coins-id, .meter--coins width value (34% at time of writing) +
        // // #deductible-id, .meter--deductible-bgnd width value (30% at time of writing),
        // // so .30 + .34 = .64;
        // console.log('cl 3');
        var deductiblePct = .34;
        var coinsPct = .31;
        var deductibleAndCoinsPct = deductiblePct + coinsPct;
        if (amountPaidPctNr == 1) {
          // console.log('cl 4');
          amountPaidPctNr = deductibleAndCoinsPct;
        } else {
          // // amountPaidPctNr * .34 ((the width of .meter--coins)) + .30 ((the width of .meter--deductible-bgnd))
          // amountPaidPctNr = (amountPaidPctNr * .34) + .30;
          // console.log('i am here: ' + amountPaidPctNr);
          // console.log('cl 5');
          if (chartInput.hasDeductible && chartInput.hasCoinsurance) {
            // console.log('cl 6');
            benefitChart.showAllThirdsGraph();
            benefitChart.showChartState('dedCoinsOop');
            // amountPaidPctNr * .34 ((the width of .meter--coins)) + .30 ((the width of .meter--deductible-bgnd))
            amountPaidPctNr = amountPaidPctNr * .34 + .30;
            // console.log('i am here 2: ' + amountPaidPctNr);
          } else {
            // console.log('cl 7');
            benefitChart.showTwoThirdsOneThirdGraph();
            // console.log('i am here 3: ' + amountPaidPctNr);
            if (chartInput.hasDeductible) {
              // console.log('cl 8');
              benefitChart.showChartState('dedOop');
              amountPaidPctNr *= deductibleAndCoinsPct;
              // console.log('i am here 4: ' + amountPaidPctNr);
            } else if (chartInput.hasCoinsurance) {
              // console.log('cl 9');
              benefitChart.showChartState('coinsOop');
              amountPaidPctNr *= deductibleAndCoinsPct;
              // console.log('i am here 5: ' + amountPaidPctNr);
            } else {
              // console.log('cl 10');
              // console.log('i am here 6: ' + amountPaidPctNr);
              benefitChart.showChartState('oop');
            }
            // console.log('cl 11');
          }
          // console.log('cl 12');
        }
        // console.log('cl 13');
      }
      // console.log('cl 14');
      amountPaidPct = (amountPaidPctNr * 100).toString() + '%';
      meterHere.css('left', amountPaidPct);
      meterProgress.css('width', amountPaidPct);

      // adjust the meter--here-description if the amountPaidPctNr is too close to the right side
      if (amountPaidPctNr > .60 && !this.isMobile()) {
        $('.meter--here-description').addClass('meter--here-on-left');
      } else {
        $('.meter--here-description').removeClass('meter--here-on-left');
      }
      // adjust the h4 if the amountPaidPctNr is too close to the top
      if (this.isMobile() && amountPaidPctNr < .20) {
        $('.meter--here-description h4').addClass('h4-near-top');
        // console.log('added h4-near-top class');
      } else {
        $('.meter--here-description h4').removeClass('h4-near-top');
        // console.log('removed h4-near-top');
      }

      var amountPaid = $('#amount-paid');
      amountPaid.html(chartInput.amountPaid);
      $('#bullet-item-1').html(chartInput.bulletItem1);
      var bulletItem2 = $('#bullet-item-2');
      if (chartInput.hasOwnProperty('bulletItem2') && chartInput.bulletItem2.trim().length > 0) {
        bulletItem2.html(chartInput.bulletItem2);
        bulletItem2.show();
      } else {
        bulletItem2.html('');
        bulletItem2.hide();
      }
      $('#deductible-text').html(chartInput.deductibleText);
      $('#coninsurance-text').html(chartInput.coinsuranceText);
      $('#oop-text').html(chartInput.oopText);
      $('#deductible-dollar-value-text').html(chartInput.deductibleDollarValueText);
      $('#max-oop-dollar-value-text').html(chartInput.maxOopDollarValueText);

      // highlighting thirds of the graph
      if (chartInput.deductibleThirdHighlight) {
        $('.meter--deductible-bgnd').removeClass('meter--deductible-bgnd-gray');
      } else {
        $('.meter--deductible-bgnd').addClass('meter--deductible-bgnd-gray');
      }
      if (chartInput.coinsThirdHighlight) {
        $('.meter--coins').addClass('meter--coins-highlighted');
      } else {
        $('.meter--coins').removeClass('meter--coins-highlighted');
      }
      if (chartInput.oopThirdHighlight) {
        $('.meter--outofpocket').addClass('meter--outofpocket-highlighted');
      } else {
        $('.meter--outofpocket').removeClass('meter--outofpocket-highlighted');
      }

      // meeting boundaries deductible
      if (chartInput.deductibleMet) {
        // the checkmark, dotted line, and text
        // var meterBgnd = $('.meter--deductible-bgnd i');
        // var meterBgnd = $('.meter--deductible-bgnd img');
        var meterBgnd = $('.meter--deductible-bgnd img, .meter--deductible-bgnd i');
        this.applyGreenCheck(meterBgnd);
        $('.meter--deductible--label').addClass('meter--limit--label-met');
        $('.meter--deductible-bgnd').addClass('meter--limit-met');
      } else {
        // Change to at original state
        // var meterBgnd = $('.meter--deductible-bgnd i');
        // var meterBgnd = $('.meter--deductible-bgnd img');
        var meterBgnd = $('.meter--deductible-bgnd img, .meter--deductible-bgnd i');
        this.applyGrayCheck(meterBgnd);
        $('.meter--deductible--label').removeClass('meter--limit--label-met');
        $('.meter--deductible-bgnd').removeClass('meter--limit-met');
      }

      // meeting boundaries out of pocket
      if (chartInput.oopMet) {
        // the checkmark, dotted line, and text
        // var meterOop = $('.meter--outofpocket i');
        // var meterOop = $('.meter--outofpocket img');
        var meterOop = $('.meter--outofpocket img, .meter--outofpocket i');
        this.applyGreenCheck(meterOop);
        $('.meter--pocket-max--label').addClass('meter--limit--label-met');
        $('.meter--coins').addClass('meter--limit-met');

        // the change of max out of pocket text to "hand and text"
        $('.meter--here, .meter--here-label').addClass('oop-met');
        $('.meter--here-label-oop-met').css('display', 'block');
      } else {
        // Change to at original state
        // the checkmark, dotted line, and text
        // var meterOop = $('.meter--outofpocket i');
        // var meterOop = $('.meter--outofpocket img');
        var meterOop = $('.meter--outofpocket img, .meter--outofpocket i');
        this.applyGrayCheck(meterOop);
        $('.meter--pocket-max--label').removeClass('meter--limit--label-met');
        $('.meter--coins').removeClass('meter--limit-met');

        // the change of max out of pocket text to "hand and text"
        $('.meter--here, .meter--here-label').removeClass('oop-met');
        $('.meter--here-label-oop-met').css('display', 'none');
      }
    } }, { key: 'applyGreenCheck', value: function applyGreenCheck(
    elem) {
      if (elem.is('img')) {
        var theImage = this.imageForEnv('/Assets/MemberPortal/Images/gmd-ic-check-circle-green-24px.svg', '/images/gmd-ic-check-circle-green-24px.svg');
        elem.attr('src', theImage);
      } else {
        elem.removeClass('icon-check-circle-gray');
        elem.addClass('icon-check-circle-green');
      }
    } }, { key: 'applyGrayCheck', value: function applyGrayCheck(
    elem) {
      if (elem.is('img')) {
        var theImage = this.imageForEnv('/Assets/MemberPortal/Images/gmd-ic-check-circle-gray-24px.svg', '/images/gmd-ic-check-circle-gray-24px.svg');
        elem.attr('src', theImage);
      } else {
        elem.removeClass('icon-check-circle-green');
        elem.addClass('icon-check-circle-gray');
      }
    } }, { key: 'imageForEnv', value: function imageForEnv(
    prodImage, feDevImage) {
      var theImage = prodImage;
      if (this.isFrontEndDev()) {
        theImage = feDevImage;
      }
      return theImage;
    } }, { key: 'isFrontEndDev', value: function isFrontEndDev()
    {
      var hostname = window.location.hostname;
      var isFrontEnd = false;
      if (hostname === 'ghp.blackprivet.com' || hostname === 'localhost' && window.location.port == 9000) {
        isFrontEnd = true;
      }
      return isFrontEnd;
    } }]);return BenefitChart;}();

// BenefitChart end