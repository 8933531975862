'use strict';var _createClass = function () {function defineProperties(target, props) {for (var i = 0; i < props.length; i++) {var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);}}return function (Constructor, protoProps, staticProps) {if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;};}();function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}var CtaSizeController = function () {
  function CtaSizeController(selectorList, mobileBp) {_classCallCheck(this, CtaSizeController);
    this.selectorList = selectorList;
    this.mobileBp = mobileBp;
  }_createClass(CtaSizeController, [{ key: 'resizeBlocks', value: function resizeBlocks(

    selector) {
      var blkAr = [];
      if ($(w).width() > this.mobileBp) {
        $(selector).each(function (i, val) {
          blkAr.push($(val).width());
        });
        $(selector).width(this.getMaxFromArray(blkAr));
      }
    }

    /**
      * Get the largest value from an array
      * @param narray integer
      **/ }, { key: 'getMaxFromArray', value: function getMaxFromArray(
    narray) {
      return Math.max.apply(null, narray);
    } }, { key: 'nodeExists', value: function nodeExists(

    nde) {
      return nde.length > 0;
    }

    /**
      * Resize vertically a nodelist to be the size of the
      * largest node element.
      * @param selector String
      **/ }, { key: 'resizeCTABlocks', value: function resizeCTABlocks(
    selector) {var _this = this;
      var blkAr = [];
      var sec = $('[class^="page-section"]');
      if ($(window).width() > this.mobileBp) {
        sec.each(function (i, val) {
          // val is page section
          $(val).find(selector).each(function (i, val2) {
            blkAr.push($(val2).height());
          });
          $(val).find(selector).height(_this.getMaxFromArray(blkAr));
          blkAr = [];
        });
      } else
      {
        if ($(selector).attr('style')) {
          $(selector).attr('style', '');
        }
      }
    } }, { key: 'ctaSizeController', value: function ctaSizeController()

    {var _this2 = this;
      this.selectorList.forEach(function (selector) {
        if (_this2.nodeExists(selector)) {
          _this2.resizeCTABlocks(selector);
        }
      });
    } }]);return CtaSizeController;}();



// To use ctaSizeController();
//
//
// function ctaSizeController() {
//   if (nodeExists('.card > div > .card-heading')) {
//     resizeCTABlocks('.card > div > .card-heading');
//   }
//   if (nodeExists('.card > div > p')) {
//     resizeCTABlocks('.card > div > p');
//   }
// }
//
// function resizeBlocks(selector) {
//   let blkAr = [];
//   if ($(w).width() > 959) {
//     $(selector).each((i, val) => {
//       blkAr.push($(val).width());
//     });
//     $(selector).width(getMaxFromArray(blkAr));
//   }
// }
//
// /**
// * Get the largest value from an array
// * @param narray integer
// **/
// function getMaxFromArray(narray) {
//   return Math.max.apply(null, narray);
// }
//
// function nodeExists(nde) {
//   return (nde.length > 0);
// }
//
// /**
// * Resize vertically a nodelist to be the size of the
// * largest node element.
// * @param selector String
// **/
// function resizeCTABlocks(selector) {
//   let blkAr = [];
//   let sec = $('[class^="page-section"]');
//   if ($(window).width() > 959) {
//     sec.each((i, val) => {
//       // val is page section
//       $(val).find(selector).each((i, val2) => {
//         blkAr.push($(val2).height());
//       });
//       $(val).find(selector).height(getMaxFromArray(blkAr));
//       blkAr = [];
//     });
//   }
//   else {
//     if ($(selector).attr('style')) {
//       $(selector).attr('style', '');
//     }
//   }
// }