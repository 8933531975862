'use strict';var _createClass = function () {function defineProperties(target, props) {for (var i = 0; i < props.length; i++) {var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);}}return function (Constructor, protoProps, staticProps) {if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;};}();function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}Storage.prototype.setObject = function (key, value) {
	this.setItem(key, JSON.stringify(value));
};
Storage.prototype.getObject = function (key) {
	return JSON.parse(this.getItem(key));
};var
MemberInfo = function () {
	function MemberInfo() {_classCallCheck(this, MemberInfo);
		var memberList;
		if (typeof Storage !== 'undefined') {
			var tmpList = sessionStorage.getObject('memberList');
			if (tmpList === null) {
				memberList = [
				{
					'name': 'Arabella Crawford',
					'address': { 'street': '123 Main St', 'street2': 'Apartment 4', 'city': 'Anytown', 'state': 'PA', 'zip': '17651' },
					'selected': true },

				{
					'name': 'Stephen Crawford',
					'address': { 'street': '123 Main St', 'street2': 'Apartment 4', 'city': 'Anytown', 'state': 'PA', 'zip': '17651' },
					'selected': true },

				{
					'name': 'Luke Crawford',
					'address': { 'street': '456 College St', 'street2': 'Apartment 4', 'city': 'College', 'state': 'CA', 'zip': '90001' },
					'selected': true }];


				sessionStorage.setObject('memberList', memberList);
			} else {
				memberList = tmpList;
			}
			// console.log('Session storage available.');
			// let foo = sessionStorage.getObject('memberList');
			// console.log('foo is: ' + JSON.stringify(foo));
		} else {
			console.log('Session storage not available.');
		}
	}_createClass(MemberInfo, [{ key: 'compareAddresses', value: function compareAddresses(

		address1, address2) {
			var sameAddress = false;
			if (address1.street == address2.street && address1.street2 == address2.street2 && address1.city == address2.city &&
			address1.state == address2.state && address1.zip == address2.zip) {
				sameAddress = true;
			}
			return true;
		} }, { key: 'updateStorageFromUI', value: function updateStorageFromUI()

		{
			var mList = sessionStorage.getObject('memberList');
			for (var ii = 0; ii < mList.length; ii++) {
				var memberId = '#member-' + ii;
				var memberElem = $(memberId);
				var value = memberElem.is(':checked');
				mList[ii].selected = value;
			}
			sessionStorage.setObject('memberList', mList);
		} }, { key: 'updateUIFromStorage', value: function updateUIFromStorage()

		{
			var mList = sessionStorage.getObject('memberList');
			for (var ii = 0; ii < mList.length; ii++) {
				var memberId = '#member-' + ii;
				$(memberId).attr('checked', mList[ii].selected);
			}
			sessionStorage.setObject('memberList', mList);
		} }]);return MemberInfo;}();