'use strict';var _createClass = function () {function defineProperties(target, props) {for (var i = 0; i < props.length; i++) {var descriptor = props[i];descriptor.enumerable = descriptor.enumerable || false;descriptor.configurable = true;if ("value" in descriptor) descriptor.writable = true;Object.defineProperty(target, descriptor.key, descriptor);}}return function (Constructor, protoProps, staticProps) {if (protoProps) defineProperties(Constructor.prototype, protoProps);if (staticProps) defineProperties(Constructor, staticProps);return Constructor;};}();function _classCallCheck(instance, Constructor) {if (!(instance instanceof Constructor)) {throw new TypeError("Cannot call a class as a function");}}var OverlayMgr = function () {
  function OverlayMgr(bpMobileValue) {_classCallCheck(this, OverlayMgr);
    this.claimOverlayList = [];
    this.bpMobileValue = bpMobileValue;
  }_createClass(OverlayMgr, [{ key: 'closeClaimsSDEditBox', value: function closeClaimsSDEditBox(

    boxToCloseId) {
      $(boxToCloseId).fadeOut(750, function () {
        $(boxToCloseId).css('display', 'none');
      });
    } }, { key: 'addOverlay', value: function addOverlay(

    backgroundId, buttonId, overlayId, cancelClass, onSubmitMethod, onCancelMethod, formId, messageDivClass, flushTopBp) {
      var overlay = new Overlay(backgroundId, buttonId, overlayId, cancelClass, null, onCancelMethod, null, null, this.bpMobileValue);
      return overlay;
    } }, { key: 'addClaimOverlay', value: function addClaimOverlay(
    buttonId, overlayId) {
      // let overlay = this.addOverlay('#claim-service-overlay', buttonId, overlayId, '.claims-sd-cancel', null, this.closeClaimsSDEditBox, null, null, this.bpMobileValue);
      var overlay = this.addOverlay('#overlay-background', buttonId, overlayId, '.claims-sd-cancel', null, this.closeClaimsSDEditBox, null, null, this.bpMobileValue);
      this.addOverlayToManagedList(overlay);
      return overlay;
    } }, { key: 'addOverlayToManagedList', value: function addOverlayToManagedList(
    overlay) {
      this.claimOverlayList.push(overlay);
    } }]);return OverlayMgr;}();



// Requires a div to gray out the rest of the page.  Needs to be a direct child
// of the body tag.  Give the div and id and pass in as backgroundId
// clickToOpen - the id of the element when clicked will present the overlay.
// Requires a div that presents the actual overlay with fields and buttons.  This div
// requires:
// overlayToOpenId - The id for the div presenting the fields and buttons.
// cancelClass - a class on elements that will close the overlayToOpenId div
// onSubmitMethod - a function that will be called when the submit action is happening.
//
// onCancelMethod - a function that will be called during the closing of
// the overlayToOpenId div.  This function is typically used to clean up fields
// on the form and any other clean up needed so the form looks right the next
// time it's presented before the page is reloaded.
// formId - id on the form that will be submitted from the overlayToOpenId
// messageDivClass - class for a div that will be presented breifly as a confirmation on
// a good submission of the form before closing the overlayToOpenId.
//
// NOTE: Responsive behavior has not yet been implemented.
//
// Example:
// let ol = new Overlay('#my-overlay', '#edit-email-id', '.edit-email-box', '.edit-email-box-cancel', closeEmailEditBox, '#user-email-form', '.email-updated');
// let ol = new Overlay('#overlay', '#request-new-cards-button-id', '.request-new-cards-box', '.request-new-cards-box-cancel', closeEmailEditBoxTest, '#user-email-form', '.email-updated');
var Overlay = function () {
  function Overlay(backgroundId, clickToOpenId, overlayToOpenId, cancelClass, onSubmitMethod, onCancelMethod, formId, messageDivClass, flushTopBp) {var _this = this;_classCallCheck(this, Overlay);
    this.backgroundId = backgroundId;
    this.clickToOpenId = clickToOpenId;
    this.overlayToOpenId = overlayToOpenId;
    this.cancelClass = cancelClass;
    this.onSubmitMethod = onSubmitMethod;
    this.onCancelMethod = onCancelMethod;
    this.formId = formId;
    this.messageDivClass = messageDivClass;
    this.flushTopBp = flushTopBp;

    $(this.clickToOpenId).click(function (event) {
      event.preventDefault();
      _this.openEditBox(_this.overlayToOpenId);
    });
    // $(this.cancelClass).click((event) => {
    var cancelElementSelector = this.overlayToOpenId + ' ' + this.cancelClass;
    $(cancelElementSelector).click(function (event) {
      event.preventDefault();
      _this.restoreGrayedOutBackground();
      //this.onCancelMethod();
      _this.onCancelMethod(_this.overlayToOpenId);
    });

    $(this.formId).submit(function (event) {
      event.preventDefault();
      var result = true;
      if (_this.onSubmitMethod != null) {
        result = _this.onSubmitMethod();
      }
      if (result) {
        _this.showPauseClose(_this.messageDivClass, _this.onCancelMethod);
      }
    });
  }_createClass(Overlay, [{ key: 'positionOverlay', value: function positionOverlay()

    {
      var elem = $(this.overlayToOpenId);
      // console.log('PO: elem.width(): ' + elem.width() + ', elem.height(): ' + elem.height());
      var leftPos = window.innerWidth / 2 - elem.width() / 2 + $('body').scrollLeft();
      //This is the wrong value and does not place the pop up in the right position.
      //var topPos = (window.innerHeight / 2) - (elem.height() / 2) + $(window).scrollTop();
      var scrollTop = $(window).scrollTop();
      // console.log('window.innerHeight: ' + window.innerHeight + ', elem.height(): ' + elem.height() + ', $(window).scrollTop(): ' + scrollTop);
      if (window.innerWidth <= this.flushTopBp) {
        elem.css('width', window.innerWidth);
      }
      // console.log('topPos: ' + topPos + ' leftPos: ' + leftPos);
      elem.css('top', scrollTop);
      elem.css('left', leftPos);
    } }, { key: 'openEditBox', value: function openEditBox(

    div_id) {
      this.grayOutBackground();
      var elem = $(div_id);
      // console.log('OEB: elem.width(): ' + elem.width() + ', elem.height(): ' + elem.height());
      this.positionOverlay();
      var pos = elem.offset();
      elem.css('z-index', 1000);
      elem.fadeIn(500, function () {
        $(div_id).css('display', 'block');
        $(div_id).css('z-index', 1000);
      });
    } }, { key: 'showPauseClose', value: function showPauseClose(
    div_id, funcToExecute) {var _this2 = this;
      $(div_id).show();
      window.setTimeout(function () {
        _this2.restoreGrayedOutBackground();
        _this2.onCancelMethod(funcToExecute);
      }, 1500);
    } }, { key: 'sizeBackground', value: function sizeBackground()
    {
      var overlay = $(this.backgroundId);
      overlay.css('height', document.body.offsetHeight);
      overlay.css('width', document.body.offsetWidth);
      overlay.css('position', 'absolute');
    } }, { key: 'grayOutBackground', value: function grayOutBackground()
    {
      var overlay = $(this.backgroundId);
      this.sizeBackground();
      overlay.css('background-color', 'gray');
      overlay.css('opacity', .8);
      overlay.css('z-index', 500);
      // overlay.css('position', 'absolute');
      $(this.backgroundId).fadeIn(200, function () {
      });
    } }, { key: 'restoreGrayedOutBackground', value: function restoreGrayedOutBackground()
    {
      var overlay = $(this.backgroundId);

      function fadeOutBg() {
        overlay.css('height', 0);
        overlay.css('width', 0);
        overlay.css('background-color', 'transparent');
        overlay.css('opacity', 0);
        overlay.css('z-index', -1);
        overlay.css('position', 'fixed');
      }

      $(this.backgroundId).fadeOut(200, fadeOutBg);
    } }]);return Overlay;}();